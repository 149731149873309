import React from "react";

import NumberFormatter from "../../../../../../components/formatters/NumberFormatter";

function Device3PhaseData({ l1, l2, l3, unit }) {
    return (
        <div className="d-flex flex-row flex-wrap">
            { [l1, l2, l3].map((value, index) => (
                <div
                    className="mr-3"
                    key={index}
                >
                    <span className="text-muted">
                        L{index + 1}:{" "}
                    </span>
                    <NumberFormatter
                        number={ value }
                        maximumFractionDigits={ 1 }
                    /> {unit}
                </div>
            ))}
        </div>
    );
}

export default React.memo(Device3PhaseData);
